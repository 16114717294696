import { isValid, isEmoji, isPhone } from '@/hooks/validate-hooks'
import { RuleObject } from 'ant-design-vue/es/form/interface'

const validatename = async (rule: RuleObject, value: string) => {
  if (!isValid(value)) {
    return Promise.reject(new Error('请输入单位名称'))
  } else if (!isEmoji(value)) {
    return Promise.reject(new Error('包含非法字符'))
  }
  return Promise.resolve()
}
const validacontact = async (rule: RuleObject, value: string) => {
  if (!isValid(value)) {
    return Promise.reject(new Error('请输入联系人姓名'))
  } else if (!isEmoji(value)) {
    return Promise.reject(new Error('包含非法字符'))
  }
  return Promise.resolve()
}
const validateContactPhone = async (rule: RuleObject, value: string) => {
  if (!isValid(value)) {
    return Promise.reject(new Error('请输入手机号'))
  } else if (!isPhone(value)) {
    return Promise.reject(new Error('手机号格式不正确'))
  }
  return Promise.resolve()
}
const validateaddress = async (rule: RuleObject, value: string) => {
  if (!isEmoji(value)) {
    return Promise.reject(new Error('包含非法字符'))
  }
  return Promise.resolve()
}

export const checkForm = () => {
  return {
    validatename,
    validacontact,
    validateContactPhone,
    validateaddress
  }
}
