
import { Moment } from 'moment'
import { defineComponent, UnwrapRef, reactive, ref, onMounted, computed } from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { creatList as fetchCeatList, getCompanyDes, editListDes, overviewList, bindSchoolOverview } from '@/api/index'
import { message } from 'ant-design-vue'
import { checkForm } from './hooks/create-hooks'
import { filterKeys } from '@/hooks/common-hooks'
import { useRouter } from 'vue-router'
interface FormState {
  platform_id: number;
  name: string;
  contact: string;
  contact_phone: string;
  // expires_time: Moment | null;
  address: string;
  remarks: string;
  // overview_id: number | string;
}
export default defineComponent({
  name: 'CreateCompany',
  props: ['id'], // 单位id
  setup (props, context) {
    const router = useRouter()
    const state = ref(false)
    const Ids = computed(() => { return props.id })
    const formState: UnwrapRef<FormState> = reactive({
      platform_id: 2,
      name: '',
      contact: '',
      contact_phone: '',
      // expires_time: null,
      address: '',
      remarks: ''
      // overview_id: ''
    })

    const {
      validatename,
      validacontact,
      validateContactPhone,
      validateaddress
    } = checkForm()

    // 表单验证
    const rules = {
      name: [{ required: true, trigger: 'blur', validator: validatename, whitespace: true }],
      contact: [{ required: true, trigger: 'blur', validator: validacontact, whitespace: true }],
      contact_phone: [{ required: true, trigger: 'change', validator: validateContactPhone, whitespace: true }],
      address: [{ required: true, trigger: 'blur', validator: validateaddress, whitespace: true }],
      remarks: [{ trigger: 'blur', validator: validateaddress, whitespace: true }]
    }
    // 单位绑定总览
    const bindSchoolOverviewHttp = (school_id: number) => {
      bindSchoolOverview({
        school_id: school_id
        // overview_id: formState.overview_id
      }).then(res => {
        console.log(res)
      })
    }
    const formRef = ref()

    const onSubmit = () => {
      formRef.value.validate().then(() => {
        fetchCeatList(formState).then((res) => {
          message.success('创建单位成功')
          // bindSchoolOverviewHttp(res.data)
          context.emit('changeIndex', 0)
          formRef.value.resetFields()
        })
      }).catch((error: ValidateErrorEntity<FormState>) => {
        console.log('error', error)
      })
    }

    // 查询详情
    const fetchList = () => {
      getCompanyDes({
        id: props.id
      }).then(res => {
        filterKeys(formState, res.data)
        state.value = res.data.state
        console.log(formState, 222)
      }).catch((error: ValidateErrorEntity<FormState>) => {
        console.log('error', error)
      })
    }

    // 编辑数据
    const fetchEdit = () => {
      formRef.value.validate().then(() => {
        editListDes(Object.assign(formState, {
          id: parseInt(props.id),
          state: state.value
        })).then(() => {
          message.success('修改成功')
          // bindSchoolOverviewHttp(props.id)
          router.replace({ name: 'CompanyManger' })
        }).catch((error: ValidateErrorEntity<FormState>) => {
          console.log('error', error)
        })
      }).catch((error: ValidateErrorEntity<FormState>) => {
        console.log('error', error)
      })
    }
    const overviewLists = ref([])
    const getOverviewList = () => {
      overviewList({}).then(res => {
        overviewLists.value = res.data.map((item: any) => {
          return {
            value: item.id,
            key: `${item.id}-${item.name}`,
            label: `${item.id}-${item.name}`
          }
        })
      })
    }
    const filterCourse = (input: string, option: any) => {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    const handleChange = (value: string) => {
      console.log(`selected ${value}`)
      // formState.overview_id = value
    }
    // 如果是编辑页面，则调用查询接口
    onMounted(() => {
      getOverviewList()
      if (props.id) {
        fetchList()
      }
    })

    return {
      handleChange,
      filterCourse,
      getOverviewList,
      overviewLists,
      Ids,
      formState,
      rules,
      onSubmit,
      formRef,
      fetchEdit
    }
  }
})
