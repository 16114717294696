
// import { SmileOutlined, DownOutlined } from '@ant-design/icons-vue'
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import { getCompanyList, deleteList, getRolemenu, editRolemenu } from '@/api/index'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import CreateCompany from './createCompany.vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { showConfirm } from '@/hooks/common-hooks'
import { getInfo } from '@/api/cookie'
interface ListProps {
  platform_id: number; // 平台学校：1教育(默认） 2工业
  search: string; // 搜索关键字(含对学校名，联系人，联系人手机）
  page: number;
  pagesize: number;
}

interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '单位名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '管理员',
    dataIndex: 'contact',
    key: 'contact'
  },
  {
    title: '联系电话',
    dataIndex: 'contact_phone',
    key: 'contact_phone'
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    customRender: (obj: {text: boolean}) => obj.text ? '启用' : '禁用'
  },
  {
    title: '账户数量',
    dataIndex: 'account_count',
    key: 'account_count'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '单位列表'
  },
  {
    id: 2,
    btnName: '新建单位'
  }
]

const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}

export default defineComponent({
  name: 'CompanyManger',
  components: {
    mangerPageHeader,
    CreateCompany
  },
  setup (props, ctx) {
    const menuVisible = ref(false)
    const school_id = ref(null)
    const userInfo = JSON.parse(getInfo() as any)
    console.log(userInfo)
    const menuList = ref([])
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination },
      searchInput: ''
    })

    // 初始化数据
    const fetchTableData = (data?: ListProps) => {
      let nowData = data
      if (!nowData) {
        nowData = {
          platform_id: 2,
          search: tableData.searchInput.trim(),
          page: tableData.pagination.current,
          pagesize: tableData.pagination.pageSize
        }
      }
      getCompanyList(nowData).then(res => {
        tableData.data = res.data.map((item: { key: number; id: number }) => {
          item.key = item.id
          return item
        })
        tableData.pagination.total = res.count ? res.count : 0
      })
    }

    // 翻页
    const changePage = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      fetchTableData()
    }

    // 搜索查询
    const fetchSearch = (current = 1) => {
      tableData.pagination.current = current
      fetchTableData()
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchTableData)
    const router = useRouter()
    // 跳转管理界面
    const goManger = (id: number) => {
      router.push({ name: 'Manger', params: { id } })
    }
    // 跳转编辑页面
    const goEditor = (id: number) => {
      router.push({ name: 'CreateCompany', params: { id } })
    }
    // 跳转总览页面
    const goOverview = (id: number) => {
      router.push({ name: 'Overview', params: { id } })
    }
    // 删除数据
    const fetchDeleteList = (id: number) => {
      showConfirm({
        content: '此操作不可逆，确定删除该单位吗?',
        callBack: () => {
          deleteList({
            id
          }).then(() => {
            message.success('删除成功')
            fetchTableData()
          })
        }
      })
    }
    const getRolemenuHttp = (id: any) => {
      getRolemenu({
        schoolid: id,
        roleid: 71
      }).then(res => {
        menuList.value = res.data
      })
    }
    const openModel = (id: any) => {
      getRolemenuHttp(id)
      menuVisible.value = true
      school_id.value = id
    }
    const getMenuIds = () => {
      return menuList.value.filter((item: any) => item?.ischeck).map((item: any) => item.id)
    }
    const handleOk = () => {
      editRolemenu({
        schoolid: school_id.value,
        roleid: 71,
        menuids: getMenuIds()
      }).then(res => {
        console.log(res)
        menuVisible.value = false
      })
    }
    onMounted(() => {
      fetchTableData()
    })
    return {
      goManger,
      size: ref('small'),
      btnList,
      ...toRefs(tableData),
      changePage,
      changeIndex,
      btnIndex,
      fetchSearch,
      goEditor,
      fetchDeleteList,
      goOverview,
      menuVisible,
      openModel,
      menuList,
      handleOk,
      userInfo
    }
  }
})
